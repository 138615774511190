
.spacer {
  height: 20px;
}

.mb {
  margin-bottom: 0.5em;
}

.flex-container {
  display: flex;
  flex-direction: column;
}